import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Vic from '../assets/images/staff/vic-van-gemert.jpg';
import Joel from '../assets/images/staff/joel-nimocks.jpg';

const staffList = [
  {
    id: 1,
    name: 'Vic Van Gemert',
    bio: (
      <p>
        Vic represents the third generation of his family in the monument
        business. Vic specializes in engraving, taking the memorial from clean
        slate to finished work of art. Vic also does cemetery work that includes
        cleaning, inscriptions, and installing monuments.
      </p>
    ),
    imgUrl: Vic,
  },
  // {
  //   id: 2,
  //   name: 'Annette Hinterthuer',
  //   bio: [
  //     "Annette comes to Van Gemert Memorials with over 20 years of experience in customer service, many of which were in sales and management with The Men's Wearhouse. Annette joined Van Gemert Memorials in August 2021. She feels customer service is number one and enjoys helping her customers remember their loved ones.  Annette is looking forward to assisting families and loved ones in commemorating their lives.",
  //     'Annette lives in Appleton, Wisconsin. She has two sons that keep her busy. Annette likes going to Brewer and Packer games with her sons. In her free time she loves being outdoors, camping, kayaking, and hiking in nature. In winter Annette loves to spend time at the beach in Florida.',
  //   ],
  //   imgUrl: Annette,
  // },
  {
    id: 2,
    name: 'Clare Little',
    bio: (
      <p>
        Clare is the store manager here at Van Gemert Memorials. You can contact
        Clare from our <Link to="/contact">contact page</Link> with any
        questions or inquiries that you have.
      </p>
    ),
  },
  {
    id: 3,
    name: 'Joel Nimocks',
    bio: (
      <p>
        Joel has been creating designs and hand-etched artwork for memorials for
        over 12 years. He is very humbled when he can honor a family member with
        one of his designs brought to fruition through the production process.
        Outside of his occupation, Joel is a painter, a musician, and enjoys
        time spent with his wife and two daughters.
      </p>
    ),
    imgUrl: Joel,
  },
];

export default function Staff() {
  return (
    <Layout
      title="Meet our Staff | Van Gemert Memorials | (920) 336-8741"
      description="Meet our caring staff - here to help you tell your story and provide you the finest quality craftsmanship and service."
      urlPath="staff"
    >
      <StaffStyles>
        <h2>Meet our staff</h2>
        <ul>
          {staffList.map(s => (
            <li key={s.id}>
              {s.imgUrl ? (
                <div className="img">
                  <img src={s.imgUrl} alt={s.name} />
                </div>
              ) : null}
              <div className="bio">
                <h3>{s.name}</h3>
                {s.bio}
              </div>
            </li>
          ))}
        </ul>
      </StaffStyles>
    </Layout>
  );
}

const StaffStyles = styled.div`
  margin: 0 auto;
  padding: 5rem 1.5rem;
  max-width: 1072px;

  h2 {
    margin: 0;
    text-align: center;
  }

  h3 {
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 1.25rem;
  }

  p {
    margin: 0 0 1.5rem;
    max-width: 600px;
    line-height: 1.65;

    &:last-of-type {
      margin: 0;
    }

    a {
      color: #4338ca;
      text-decoration: underline;
    }
  }

  ul {
    margin: 0;
  }

  li {
    padding: 3.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #e2e8f0;

    &:last-of-type {
      margin: 0;
      border: none;
    }
  }

  .img {
    margin: 0 0 2rem;
    width: 200px;
    height: 250px;
    flex-shrink: 0;
    border: 6px solid #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  @media (min-width: 768px) {
    h2 {
      padding-bottom: 1.25rem;
      text-align: left;
      border-bottom: 1px solid #e2e8f0;
    }

    li {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;

      .bio {
        padding: 0 0 0 3rem;
      }
    }

    .img {
      margin-bottom: 0.5rem;
    }
  }
`;
